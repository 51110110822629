import $cookies from 'vue-cookies';
import store from '@/store';
import * as roleService from '@/utils/role';
import axios from 'axios';

export function authMiddleware(to, from, next) {
    // Perform authentication check or any other logic

    const isAuthenticated = $cookies?.isKey('user_token');

    const userAboutActive = $cookies?.isKey('user');
    store.commit('setGlobalSearch', '');
    if (isAuthenticated && userAboutActive) {
        // User is authenticated, proceed to the next route
        return next();
    } else {
        // User is not authenticated, redirect to login or another route
        return next('/auth/login');
    }
}

export function checkLoginAndToken(to, from, next) {
    if (!to.params.token) {
        return next('/');
    }
    const token = to.params.token;

    axios
        .get(process.env.VUE_APP_MAIN_URL + '/account', {
            headers: {
                Authorization: `Bearer ${token || null}`,
            },
        })
        .then((res) => {
            const response = res.data.data;
            $cookies.set('user_token', token);
            $cookies.set('user', JSON.stringify(response));
            store.commit('setImgAdmin', response?.img_admin);
            store.commit('setNameAdmin', response?.name);

            store.state.justSigned = true;
            return next('/');
        })
        .catch((_) => {
            return next('/');
        });
}

export function isGP(to, from, next) {
    if (roleService.isGP()) {
        return next();
    } else {
        return next('/');
    }
}
export function isCallCenterBoss(to, from, next) {
    if (roleService.isCallCenterBoss()) {
        return next();
    } else {
        return next('/');
    }
}

export function isAdmin(to, from, next) {
    if (roleService.isAdmin()) {
        return next();
    } else {
        return next('/');
    }
}
export function isCompany(to, from, next) {
    if (roleService.isCompany()) {
        return next();
    } else if (roleService.isCLDomain()) {
        return next();
    } else {
        return next('/');
    }
}

export function isCompanyOrCompanyManager(to, from, next) {
    if (roleService.isCompany()) {
        return next();
    } else if (roleService.isCLDomain()) {
        return next();
    } else if (roleService.isCompanyManager()) {
        return next();
    } else {
        return next('/');
    }
}

export function isCompanyOrCompanyManagerOrCompanyLaborant(to, from, next) {
    if (roleService.isCompany()) {
        return next();
    } else if (roleService.isCLDomain()) {
        return next();
    } else if (roleService.isCompanyManager()) {
        return next();
    } else if (roleService.isCompanyLaborant()) {
        return next();
    } else {
        return next('/');
    }
}

export function isContentManager(to, from, next) {
    if (roleService.isContentManager()) {
        return next();
    } else {
        return next('/');
    }
}

export function isCallCenter(to, from, next) {
    if (roleService.isCallCenter()) {
        return next();
    } else {
        return next('/');
    }
}

export function isAdminOrCallCenter(to, from, next) {
    if (roleService.isAdmin()) {
        return next();
    } else if (roleService.isCallCenter()) {
        return next();
    } else return next('/');
}

export function isAdminOrGP(to, from, next) {
    if (roleService.isAdmin()) {
        return next();
    } else if (roleService.isGP()) {
        return next();
    } else return next('/');
}
export function isAdminOrContentManager(to, from, next) {
    if (roleService.isAdmin()) {
        return next();
    } else if (roleService.isContentManager()) {
        return next();
    } else return next('/');
}

export function isAdminOrCallCenterOrCallCenterBoss(to, from, next) {
    if (roleService.isAdmin()) {
        return next();
    } else if (roleService.isCallCenter()) {
        return next();
    } else if (roleService.isCallCenterBoss()) {
        return next();
    } else return next('/');
}

export function isAdminOrCallCenterBoss(to, from, next) {
    if (roleService.isAdmin()) {
        return next();
    } else if (roleService.isCallCenterBoss()) {
        return next();
    } else return next('/');
}

export function isAdminOrAccountant(to, from, next) {
    if (roleService.isAdmin()) {
        return next();
    } else if (roleService.isAccountant()) {
        return next();
    } else return next('/');
}
