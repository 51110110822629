import {
    authMiddleware,
    isAdmin,
    isAdminOrContentManager,
    isAdminOrGP,
    isAdminOrCallCenter,
    isAdminOrCallCenterOrCallCenterBoss,
    isAdminOrCallCenterBoss,
    isAdminOrAccountant,
} from '@/middleware';

export const Admin = [
    {
        path: '/admin/notifications',
        name: 'notifications',
        beforeEnter: [authMiddleware, isAdmin],
        component: () => import('@/views/admin/notifications'),
    },
    {
        path: '/admin/messages',
        name: 'messages',
        beforeEnter: [authMiddleware, isAdmin],
        component: () => import('@/views/admin/messages'),
    },
    {
        path: '/admin/ip-telephony',
        name: 'ip_telephony',
        beforeEnter: [authMiddleware, isAdminOrCallCenter],
        component: () => import('@/views/admin/ip-telephony'),
    },
    {
        path: '/admin/questionnaire',
        name: 'questionnaire',
        beforeEnter: [authMiddleware, isAdminOrCallCenterOrCallCenterBoss],
        component: () => import('@/views/admin/questionnaire'),
    },
    {
        path: '/admin/technical_support',
        name: 'technical_support',
        beforeEnter: [authMiddleware, isAdminOrCallCenterOrCallCenterBoss],
        component: () => import('@/views/admin/technical_support'),
    },
    {
        path: '/admin/treatment-category',
        name: 'treatment-category',
        beforeEnter: [authMiddleware, isAdminOrCallCenterOrCallCenterBoss],
        component: () => import('@/views/admin/treatment-category'),
    },

    {
        path: '/admin/dispatcher',
        name: 'Admin-dispatcher',
        beforeEnter: [authMiddleware, isAdminOrGP],
        component: () => import('@/views/admin/dispatcher'),
    },
    {
        path: '/admin/clinic-login',
        name: 'clinic-login',
        beforeEnter: [authMiddleware, isAdmin],
        component: () => import('@/views/admin/clinic-login'),
    },
    {
        path: '/admin/one-id-statistics',
        name: 'one-id-statistics',
        beforeEnter: [authMiddleware, isAdmin],
        component: () => import('@/views/admin/one-id-statistics'),
    },

    {
        path: '/admin/black-list',
        name: 'Admin-black-list',
        beforeEnter: [authMiddleware, isAdmin],
        component: () => import('@/views/admin/black-list'),
    },
    {
        path: '/admin/admins',
        name: 'Admins',
        beforeEnter: [authMiddleware, isAdmin],
        component: () => import('@/views/admin/admins'),
    },
    {
        path: '/admin/map',
        name: 'Admin-map',
        beforeEnter: [authMiddleware, isAdminOrCallCenterBoss],
        component: () => import('@/views/admin/map'),
    },

    {
        path: '/admin/users',
        name: 'users-all',
        beforeEnter: [authMiddleware, isAdminOrCallCenterOrCallCenterBoss],
        component: () => import('@/views/admin/users'),
    },
    {
        path: '/admin/users/:id/feedbacks',
        name: 'user-feedbacks',
        beforeEnter: [authMiddleware, isAdminOrCallCenterOrCallCenterBoss],
        component: () => import('@/views/admin/users/id/feedbacks'),
    },
    {
        path: '/admin/users/:id/orders',
        name: 'user-orders',
        beforeEnter: [authMiddleware, isAdminOrCallCenterOrCallCenterBoss],
        component: () => import('@/views/admin/users/id/orders'),
    },
    {
        path: '/admin/users/:id/reports',
        name: 'user-reports',
        beforeEnter: [authMiddleware, isAdminOrCallCenterOrCallCenterBoss],
        component: () => import('@/views/admin/users/id/reports'),
    },
    {
        path: '/admin/users/:id/questionnaire',
        name: 'user-questionnaire',
        beforeEnter: [authMiddleware, isAdminOrCallCenterOrCallCenterBoss],
        component: () => import('@/views/admin/users/id/questionnaire'),
    },
    {
        path: '/admin/users/:id/map',
        name: 'user-map',
        beforeEnter: [authMiddleware, isAdminOrCallCenterOrCallCenterBoss],
        component: () => import('@/views/admin/users/id/user-map'),
    },
    {
        path: '/admin/users/patient-info/:id',
        name: 'patient-info',
        beforeEnter: [authMiddleware, isAdminOrCallCenterOrCallCenterBoss],
        component: () => import('@/views/admin/users/id/patient-info'),
    },
    {
        path: '/admin/services',
        name: 'services-all',
        beforeEnter: [authMiddleware, isAdmin],
        component: () => import('@/views/admin/services'),
    },
    {
        path: '/admin/orders',
        name: 'orders-all',
        beforeEnter: [authMiddleware, isAdminOrCallCenterOrCallCenterBoss],
        component: () => import('@/views/admin/orders'),
    },
    {
        path: '/admin/orders/:id',
        name: 'order-id',
        beforeEnter: [authMiddleware, isAdminOrCallCenterOrCallCenterBoss],
        component: () => import('@/views/admin/orders/id/view'),
    },
    {
        path: '/admin/orders/edit/:id',
        name: 'order-edit-id',
        beforeEnter: [authMiddleware, isAdmin],
        component: () => import('@/views/admin/orders/id/edit'),
    },
    {
        path: '/admin/symptoms',
        name: 'symptoms',
        beforeEnter: [authMiddleware, isAdmin],
        component: () => import('@/views/admin/symptoms'),
    },
    {
        path: '/admin/professions',
        name: 'proffessions-all',
        beforeEnter: [authMiddleware, isAdmin],
        component: () => import('@/views/admin/professions'),
    },
    {
        path: '/admin/news',
        name: 'news',
        beforeEnter: [authMiddleware, isAdminOrContentManager],
        component: () => import('@/views/admin/news'),
    },
    {
        path: '/admin/news/add',
        name: 'news-add',
        beforeEnter: [authMiddleware, isAdminOrContentManager],
        component: () => import('@/views/admin/news/add'),
    },
    {
        path: '/admin/video-podcast',
        name: 'Video-podcast',
        beforeEnter: [authMiddleware, isAdminOrContentManager],
        component: () => import('@/views/admin/video-podcast'),
    },
    {
        path: '/admin/video-podcast/add',
        name: 'VideoPodcastAdd',
        beforeEnter: [authMiddleware, isAdminOrContentManager],
        component: () => import('@/views/admin/video-podcast/add'),
    },
    {
        path: '/admin/vebinar',
        name: 'Vebinar',
        beforeEnter: [authMiddleware, isAdminOrContentManager],
        component: () => import('@/views/admin/vebinar'),
    },
    {
        path: '/admin/vebinar/add',
        name: 'VebinarAdd',
        beforeEnter: [authMiddleware, isAdminOrContentManager],
        component: () => import('@/views/admin/vebinar/add'),
    },
    {
        path: '/admin/news/view/:id',
        name: 'NewsView',
        beforeEnter: [authMiddleware, isAdminOrContentManager],
        component: () => import('@/views/admin/news/view'),
    },
    {
        path: '/admin/about-company',
        name: 'AboutCompany',
        beforeEnter: [authMiddleware, isAdminOrContentManager],
        component: () => import('@/views/admin/about-company'),
    },
    {
        path: '/admin/about-company/add',
        name: 'AboutCompanyAdd',
        beforeEnter: [authMiddleware, isAdminOrContentManager],
        component: () => import('@/views/admin/about-company/add'),
    },
    {
        path: '/admin/about-app',
        name: 'AboutApp',
        beforeEnter: [authMiddleware, isAdminOrContentManager],
        component: () => import('@/views/admin/about-app'),
    },
    {
        path: '/admin/about-app/add',
        name: 'AboutAppAdd',
        beforeEnter: [authMiddleware, isAdminOrContentManager],
        component: () => import('@/views/admin/about-app/add'),
    },
    {
        path: '/admin/contacts',
        name: 'Contacts',
        beforeEnter: [authMiddleware, isAdminOrContentManager],
        component: () => import('@/views/admin/contacts'),
    },
    {
        path: '/admin/contacts/add',
        name: 'ContactsAdd',
        beforeEnter: [authMiddleware, isAdminOrContentManager],
        component: () => import('@/views/admin/contacts/add'),
    },
    {
        path: '/admin/rate',
        name: 'AdminRate',
        beforeEnter: [authMiddleware, isAdmin],
        component: () => import('@/views/admin/rate'),
    },
    {
        path: '/admin/reasons',
        name: 'Reasons',
        beforeEnter: [authMiddleware, isAdmin],
        component: () => import('@/views/admin/reasons'),
    },
    {
        path: '/admin/reports',
        name: 'Reports-Admin',
        beforeEnter: [authMiddleware, isAdminOrAccountant],
        component: () => import('@/views/admin/reports'),
    },
    {
        path: '/admin/medical_cards',
        name: 'Reports-medical_cards',
        beforeEnter: [authMiddleware, isAdmin],
        component: () => import('@/views/admin/medical_cards'),
    },
    {
        path: '/admin/comments',
        name: 'Comments',
        beforeEnter: [authMiddleware, isAdmin],
        component: () => import('@/views/admin/comments'),
    },
    {
        path: '/admin/self-employed-status',
        name: 'self-employed-status',
        beforeEnter: [authMiddleware, isAdminOrAccountant],
        component: () => import('@/views/admin/self-employed-status'),
    },
    {
        path: '/admin/feedbacks',
        name: 'Feedbacks',
        beforeEnter: [authMiddleware, isAdminOrCallCenter],
        component: () => import('@/views/admin/feedback'),
    },

    {
        path: '/admin/conversations',
        name: 'Conversations',
        beforeEnter: [authMiddleware, isAdmin],
        component: () => import('@/views/admin/conversations'),
    },
    {
        path: '/admin/chat',
        name: 'Chat',
        beforeEnter: [authMiddleware],
        component: () => import('@/views/admin/chat'),
    },
    {
        path: '/admin/clinic',
        name: 'clinic',
        beforeEnter: [authMiddleware, isAdminOrContentManager],
        component: () => import('@/views/admin/clinic'),
    },
    {
        path: '/admin/clinic/add',
        name: 'clinic-add',
        beforeEnter: [authMiddleware, isAdminOrContentManager],
        component: () => import('@/views/admin/clinic/add'),
    },
];
