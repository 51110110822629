export const ROLE_CONTENT_MANAGER = 0;
export const ROLE_ADMIN = 1;
export const ROLE_COMPANY = 2;
export const ROLE_DOCTOR = 3;
export const ROLE_PATIENT = 4;
export const ROLE_CALL_CENTER = 5;
export const ROLE_CALL_CENTER_BOSS = 6;
export const ROLE_GP = 7;
export const ROLE_COMPANY_MANAGER = 8;
export const ROLE_ROLE_COMPANY_LABORANT = 9;
export const ROLE_ACCOUNTANT = 10;

export const ROLE_CALL_CENTER_TEXT = 'call_center';
export const ROLE_CALL_CENTER_BOSS_TEXT = 'call_center_boss';
export const ROLE_CONTENT_MANAGER_TEXT = 'content_manager';
export const ROLE_ADMIN_TEXT = 'admin';
export const ROLE_COMPANY_TEXT = 'company';
export const ROLE_DOCTOR_TEXT = 'doctor';
export const ROLE_PATIENT_TEXT = 'patient';
export const ROLE_GP_TEXT = 'gp';
export const ROLE_MIDDLE_MEDICAL_TEXT = 'middle_medical';
export const ROLE_COMPANY_MANAGER_TEXT = 'company_manager';
export const ROLE_ROLE_COMPANY_LABORANT_TEXT = 'company_laborant';
export const ROLE_ACCOUNTANT_TEXT = 'accountant';

export const ROLES = {
    [ROLE_CALL_CENTER]: ROLE_CALL_CENTER_TEXT,
    [ROLE_CONTENT_MANAGER]: ROLE_CONTENT_MANAGER_TEXT,
    [ROLE_ADMIN]: ROLE_ADMIN_TEXT,
    [ROLE_COMPANY]: ROLE_COMPANY_TEXT,
    [ROLE_DOCTOR]: ROLE_DOCTOR_TEXT,
    [ROLE_PATIENT]: ROLE_PATIENT_TEXT,
    [ROLE_GP]: ROLE_GP_TEXT,
    [ROLE_CALL_CENTER_BOSS]: ROLE_CALL_CENTER_BOSS_TEXT,
    [ROLE_COMPANY_MANAGER]: ROLE_COMPANY_MANAGER_TEXT,
    [ROLE_ROLE_COMPANY_LABORANT]: ROLE_ROLE_COMPANY_LABORANT_TEXT,
    [ROLE_ACCOUNTANT]: ROLE_ACCOUNTANT_TEXT,
};
